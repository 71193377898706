import { StaticImage } from "gatsby-plugin-image";
import React, { FunctionComponent } from "react";
import {
  Button,
  FeatureCard,
  HeroHeader,
  MainLayout,
  Seo,
  CalloutBox,
  CalloutBoxTitle,
  CalloutBoxText,
  CalloutBoxButtons,
} from "../components";

const SupportPage: FunctionComponent = () => {
  return (
    <MainLayout>
      <Seo
        title="Support"
        description="Hilla is built and supported by Vaadin, a company with more than 20 years of experience helping businesses build web applications."
      />

      {/* Hero */}
      <section className="container py-md md:py-xl md:grid gap-8 grid-cols-12 items-center">
        <div className="md:pr-20 col-span-7">
          <HeroHeader>
            Open source,{" "}
            <span className="text-blue-500 dark:text-blue-400">
              built for business
            </span>
          </HeroHeader>

          <p className="mt-4 text-lg">
            Hilla is an open-source framework that is designed to build
            enterprise grade web apps. You can find the source code on{" "}
            <a className="link" href="https://github.com/vaadin/hilla">
              GitHub
            </a>
            , connect with community members on{" "}
            <a className="link" href="https://discord.gg/vaadin">
              Discord
            </a>
            , and ask questions on{" "}
            <a
              className="link"
              href="https://stackoverflow.com/questions/tagged/hilla"
            >
              Stack Overflow
            </a>
            .
          </p>

          <p className="mt-12 text-sm">
            Support options are available on{" "}
            <a className="link" href="https://vaadin.com"></a>Vaadin website.
          </p>

          <Button
            className="mt-6 w-full md:w-auto"
            href="https://vaadin.com/solutions/support"
          >
            <span>Explore support options</span>
            <i className="ml-1.5 text-xl las la-arrow-right"></i>
          </Button>
        </div>

        <StaticImage
          className="mt-12 md:mt-0 col-span-5"
          src="../images/support/support-hero-art.png"
          alt="Vaadin support"
        />
      </section>

      <section className="py-md md:py-xl container">
        <div className="max-w-4xl mx-auto text-center items-center">
          <div className="mb-2 text-sm font-bold uppercase tracking-wider">
            <span className="text-blue-to-violet">
              Built and supported by Vaadin
            </span>
          </div>
          <h1 className="text-5xl leading-tight">
            Get support backed by more than 20 years of experience
          </h1>
          <p className="mt-4">
            Hilla is built and supported by{" "}
            <a className="link" href="https://vaadin.com">
              Vaadin
            </a>
            , a company with more than 20 years of experience helping businesses
            build web applications. We work with companies across healthcare,
            finance, insurance, logistics, government, and aerospace - including
            more than 40% of Fortune 100 companies. We'll ensure your Hilla
            project succeeds.
          </p>
        </div>

        <section className="py-md md:pt-xl grid md:grid-cols-2 gap-8">
          <FeatureCard title="Expert chat" iconClass="las la-comments">
            <p>
              Chat-based help with our engineers (with an average 4.7/5 customer
              rating)
            </p>
          </FeatureCard>

          <FeatureCard
            title="Extended warranty and unlimited bug fixes"
            iconClass="las la-bug"
            theme="success"
          >
            <p>
              Get up to 15 years of support for any release and priority fixes
              for bugs you find.
            </p>
          </FeatureCard>

          <FeatureCard
            title="Expert on demand"
            iconClass="las la-hands-helping"
            theme="accent"
          >
            <p>
              Work in person with our engineers and designers. We can help you
              get your project set up, build custom components, deliver custom
              themes, build prototypes, and debug issues. Great for those times
              you wish you had a Hilla expert on your team.
            </p>
          </FeatureCard>

          <FeatureCard
            title="Mentor"
            iconClass="las la-user-tie"
            theme="accent2"
          >
            <p>
              Work with a dedicated senior engineer who can coach and support
              your team. The mentor can help solve problems, give technical
              guidance, build proofs-of-concept, and much more.
            </p>
          </FeatureCard>
        </section>
      </section>

      <section className="pb-xl container">
        <CalloutBox>
          <CalloutBoxTitle>Tell us about your support needs</CalloutBoxTitle>

          <CalloutBoxText>
            Let's build the support package you need, together.
          </CalloutBoxText>

          <CalloutBoxButtons>
            <Button
              className="w-full md:w-auto"
              href="https://vaadin.com/solutions/support"
            >
              <span>Explore support options</span>
              <i className="ml-1.5 text-xl las la-arrow-right"></i>
            </Button>
          </CalloutBoxButtons>
        </CalloutBox>
      </section>
    </MainLayout>
  );
};

export default SupportPage;
